exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-aktionen-tsx": () => import("./../../../src/pages/aktionen.tsx" /* webpackChunkName: "component---src-pages-aktionen-tsx" */),
  "component---src-pages-aktuelles-tsx": () => import("./../../../src/pages/aktuelles.tsx" /* webpackChunkName: "component---src-pages-aktuelles-tsx" */),
  "component---src-pages-datenschutz-tsx": () => import("./../../../src/pages/datenschutz.tsx" /* webpackChunkName: "component---src-pages-datenschutz-tsx" */),
  "component---src-pages-impressum-tsx": () => import("./../../../src/pages/impressum.tsx" /* webpackChunkName: "component---src-pages-impressum-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-kontakt-tsx": () => import("./../../../src/pages/kontakt.tsx" /* webpackChunkName: "component---src-pages-kontakt-tsx" */),
  "component---src-pages-mitmachen-tsx": () => import("./../../../src/pages/mitmachen.tsx" /* webpackChunkName: "component---src-pages-mitmachen-tsx" */),
  "component---src-pages-patient-innen-tsx": () => import("./../../../src/pages/patient_innen.tsx" /* webpackChunkName: "component---src-pages-patient-innen-tsx" */),
  "component---src-pages-ueberuns-tsx": () => import("./../../../src/pages/ueberuns.tsx" /* webpackChunkName: "component---src-pages-ueberuns-tsx" */)
}

